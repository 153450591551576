//import './index.css';
//import './App.css';
import reportWebVitals from './reportWebVitals';
import Header from './components/Header';
import Footer from './components/Footer';
import HeadingLv2 from './components/HeadingLv2';
import HeadingLv3 from './components/HeadingLv3';
import Paragraph from './components/Paragraph';

export const Flow= () => {
  return (
    <div>
      <Header />
      <main className='main'>
        <section>
          Flow
          <HeadingLv2>はじめに</HeadingLv2>
          <Paragraph>厚生労働省によると、うつ病は、一生の内に100人に約6人がかかる病気とのことです。<br/>
          ライフステージ、職場環境、家庭環境は、人によりさまざまですが、いつどこでうつ病になるかわかりません。<br/>
          また、うつ病より軽いと言われる、適応障害というものも社会問題となっています。<br/>
          わたしは、仕事量の多さなどで適応障害となり数ヶ月間療養してきました。<br/>
          ここでは、適応障害の経験を元に日々を健康に過ごすためのアイデアをご提供します。</Paragraph>
        </section>
        <section>
          <HeadingLv2>つらいなら逃げても良い</HeadingLv2>
          <Paragraph>どうしても辛く仕事ができない時は、逃げても良いと思います。<br/>
          お客様に迷惑をかける、同僚に迷惑をかける、会社に迷惑をかけるいろいろなことを考えると思います。<br/>
          いろいろな考えが頭を過ぎると思います。それでも自分自身の健康が一番重要です。何より自分の健康・安全を最優先です。</Paragraph>
        </section>
        <section>
          <HeadingLv2>うつ病・適応障害からの復帰</HeadingLv2>
          <HeadingLv3>復帰までの流れ</HeadingLv3>
          <Paragraph>うつ病・適応障害から復帰の流れを知っておくが非常に重要になります。<br/>
          仕事を休んでから復帰までは、安全に復帰するために通常以下の様なプロセスがあり、軽度の適応障害でも最低2ヶ月ほどはかかります。</Paragraph>
          <ol>
            <li>ストレス状態・うつ状態</li>
            <li>主治医の診断</li>
            <li>自宅療養</li>
            <li>通院</li>
            <li>生活リズム表・リワークなど</li>
            <li>復帰判断</li>
          </ol>
        </section>
        <section>
          <HeadingLv2>その他</HeadingLv2>
          <HeadingLv3>海外の取り組み</HeadingLv3>
          <Paragraph>精神的な健康のためのプログラムがあるカナダ。<br/>
          カナダには、<a href="https://www.parkprescriptions.ca" rel="noopener noreferrer" target="_blank">PaRX</a>と呼ばれるプログラムがあり、
          認定の専門医が国立公園の年間パスを処方できる。</Paragraph>
          <Paragraph>市民の心の健康サポートする54のプログラム。アメリカ、ニューヨーク。<br/>
          アメリカ、ニューヨークには、<a href="https://nycsmh.org/thrivenyc/" rel="noopener noreferrer" target="_blank">ThriveNYC</a>と呼ばれるプログラムで、
          市民のメンタルヘルスの改善へ取り組みをしている。</Paragraph>
        </section>
      </main>
      <Footer />
    </div>
  );
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
