import React from 'react'
import { Link } from "react-router-dom";

export default class GlobalNavi extends React.Component {
    render() {
        return (
            <nav className='globalNavi'>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/Flow">復帰までの流れ詳細</Link></li>
            </nav>
        ) 
    }
}