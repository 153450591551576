import React from 'react'

export default class Footer extends React.Component {
    render() {
        return (
            <footer className='footer'>
                <p>&copy;gooddailylife.com</p>
            </footer>
        ) 
    }
}