import { BrowserRouter, Link, Routes, Route } from "react-router-dom";

import { Home } from "./Home";
import { Flow } from "./Flow";

function App() {
  return (
    <BrowserRouter>
    <div className="App">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/Flow" element={<Flow />} /> 
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
