import React from 'react'
import GlobalNavi from './GlobalNavi'

export default class Header extends React.Component {
    render() {
        return (
            <>
                <header className='header'>
                    <h1 className='logo'><i class="fa-solid fa-heart-pulse fa-2x"></i>good daily life</h1>
                    <p>良い日常を送るためのアドバイス。うつ病・適応障害経験者の日々の工夫を紹介します。</p>
                    <GlobalNavi />
                </header>
            </>
        ) 
    }
}